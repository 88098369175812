import PropTypes from 'prop-types'
import React, { useState } from 'react'
import { WithResources } from '@edulastic/common'
import appConfig from '../../../app-config'
import { AUTH_FLOW, AUTH_METHOD, DEFAULT_SCOPES } from '../constants'
import GoogleAuthRetryModal from '../../../client/author/ManageClass/components/GoogleAuthRetry'

const { googleClientSdkUrl, googleApiSdkUrl } = appConfig
const ACCESS_DENIED = 'access_denied'
const GoogleLoginWrapper = ({
  WrappedComponent,
  successCallback,
  errorCallback,
  scopes = DEFAULT_SCOPES,
  flowType = AUTH_FLOW.IMPLICIT,
  loadGapi = false,
  prompt,
  onScriptLoad,
  onRetry = null,
  isHangoutMeet,
}) => {
  const [googleClient, setGoogleClient] = useState(null)
  const [showAuthRetry, setShowAuthRetry] = useState(false)
  const resources = loadGapi
    ? [googleClientSdkUrl, googleApiSdkUrl]
    : [googleClientSdkUrl]
  const handleLoginSuccess = (response) => {
    const { scope: grantedScopes, code, error } = response

    // If the user denied access, show the retry modal
    // When no permission given or canceled the request, then we get access_denied
    if (error === ACCESS_DENIED) {
      setShowAuthRetry(true)
      return
    }

    // If there's no authorization code, proceed with the success callback
    // In other case where request is to not get code, for example when requesting for access token
    // so in that case just connecting existing functionality by calling success callback
    if (!code && !grantedScopes) {
      successCallback(response)
      return
    }

    // Check if all required scopes are granted
    const requiredScopes = scopes.split(' ')
    const grantedScopesList = grantedScopes.split(' ')
    const allScopesGranted = requiredScopes.every((scope) =>
      grantedScopesList.includes(scope)
    )

    // Proceed with success callback if all scopes are granted, otherwise show the retry modal
    if (allScopesGranted) {
      successCallback(response)
    } else {
      setShowAuthRetry(true)
    }
  }
  const isGoogleReady = () => !!window.google
  const onApiLoad = () => {
    if (isGoogleReady()) {
      const clientMethod =
        flowType === AUTH_FLOW.IMPLICIT ? AUTH_METHOD.TOKEN : AUTH_METHOD.CODE
      setGoogleClient(
        window.google?.accounts.oauth2[clientMethod]({
          client_id: process.env.REACT_APP_GOOGLE_CLIENT_ID,
          scope: scopes,
          prompt,
          callback: onRetry ? handleLoginSuccess : successCallback,
          error_callback: errorCallback,
        })
      )
    }
    if (onScriptLoad) {
      onScriptLoad()
    }
  }

  const handleCancel = () => {
    setShowAuthRetry(false)
  }

  return (
    <WithResources resources={resources} fallBack={<></>} onLoaded={onApiLoad}>
      <WrappedComponent googleClient={googleClient} />
      <GoogleAuthRetryModal
      isHangoutMeet={isHangoutMeet}
        visible={showAuthRetry}
        onCancel={handleCancel}
        onRetry={() => {
          handleCancel()
          onRetry(googleClient)
        }}
      />
    </WithResources>
  )
}

GoogleLoginWrapper.propTypes = {
  WrappedComponent: PropTypes.func.isRequired,
  successCallback: PropTypes.func.isRequired,
  errorCallback: PropTypes.func.isRequired,
}

export default GoogleLoginWrapper
